<script lang="ts" setup>
import { ref, computed, getCurrentInstance } from "vue";
import UserGroupsRelationship from "@/components/iam/UserGroupsRelationship.vue";
import { iamType, addRelationship, removeUserGroupFromUser } from "@/services/api/iam.api";

const props = defineProps({
  userId: undefined,
});

const refreshKey = ref(0);

const accessSelection = computed(() => {
  return { domain: iamType.User, id: props.userId };
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function onAddRelationship(selection) {
  console.log("UserUserGroups onAddRelationship", selection);
  const response = await addRelationship(iamType.User, props.userId, selection);
  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Toewijzen van de groepen aan een gebruiker is mislukt! " + response.error);
    }
  }
}

async function onRemoveRelationship(id) {
  console.log("UserUserGroups onRemoveRelationship", id);
  const response = await removeUserGroupFromUser(props.userId, id);
  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Verwijder van de groep aan een gebruiker is mislukt! " + response.error);
    }
  }
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <user-groups-relationship
      @add-relationship="onAddRelationship"
      allow-remove="true"
      @remove-relationship="onRemoveRelationship"
      :refreshKey="refreshKey"
      :viaAccess="accessSelection"
    ></user-groups-relationship>
  </v-container>
</template>
